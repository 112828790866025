<template>
    <div class="choice-button">
      <div v-for="(choice,k) in value.options" 
      :key="choice.name" 
      :class="['card', { 'card-checked': value.selected == k }]" 
      @click="value.selected = k">
        <div class="radio-row">
            <input
            type="radio"
            :id="choice.name"
            :value="choice.name"
            v-model="selectedChoice"
            class="radio-button"
            />
            <label class='v-label' :for="choice.name"> <img v-if="k == value.selected" src="/images/check.svg" /> <img v-if="k != value.selected" src="/images/uncheck.svg" /> {{ choice.name }}</label>
            <img :src="choice.img" 
            v-if="choice.img"
            :style="{}/*imageStyle(k)*/"
            alt="no image" class="choice-image mx-auto cursor-pointer">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ChoiceButton',
    props:{
        size:{default:1.0},
        value:{type:Object,required:true},
        direction:{
            default:"row"
        },
        options:{
            type:Array,
        },
    },
    data() {
      return {
        selectedChoice: null,
        selected: null
      };
    },
    computed:{

        className(){
            return ''
            /*
            return "flex-"+this.direction+" "
             +(this.direction=='column'?'align-items-center':'align-items-stretch');
             */
        }
    }
  };
  </script>
  
  <style scoped>
  .choice-button {
    display: flex;
    flex-direction: column;
  }
  
  .radio-row {
    display: flex;
    flex-direction: row;
  }
  
  .card {
    border: 1px solid #ccc;
    margin: 5px 0;
    border-radius: 5px;
    float: left;
    clear: none;
    display: block;
  }

  .card-checked {
    border: 2px solid #082768;
  }

  .v-label {
    right: 0px !important;
    text-align: start;
    display: flex;
    align-items: center;
    padding-left: 1em;
    text-transform: uppercase;
    font-family: 'Roboto';
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .radio-button {
    border: 0px;
    width: 1em;
    height: 2em;
    margin: 10px;
    background-image: url('/images/uncheck.svg');
    padding-top: 20px;
    padding-bottom: 20px;
}

.radio-button:checked::after {
    border: 0px;
    width: 100%;
    height: 2em;
    background-image: url('/images/check.svg');
}
img {
  width: 2vw;
  height: 2vw;
  padding: 5px;
}

input[type=radio] {
  display: none;
}

img:hover {
  opacity:0.6;
  cursor: pointer;
}

img:active {
  opacity:0.4;
  cursor: pointer;
}

.choice-image{
    height: 86px;
    width: 86px;
}

  </style>