<template>
    <div class="increment-decrement-button">
      <button @click="decrement">-</button>
      <span>{{ getValue() }}</span>
      <button @click="increment">+</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'IncrementDecrementButton',
    props: {
      value: {
        type: Number,
        default: 1
      }
    },
    data() {
        return {
            value_data: this.value
        }
    },
    methods: {
      increment() {
        this.value_data = this.value_data + 1;
      },
      decrement() {
        this.value_data = this.value_data - 1;
      },
      getValue(){
        return this.value_data;
      }
    }
  };
  </script>
  
  <style scoped>
  .increment-decrement-button {
    display: flex;
    align-items: center;
  }
  
  .increment-decrement-button button {
    width: 30px;
    height: 30px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #92cfe0;
    padding: 24px;
    color:#92cfe0;
    background-color:#ffffff;
    border-color: #92cfe0;
    border-radius: 10%;
    border-width: 4px;
  }
  
  .increment-decrement-button span {
    font-size: 20px;
    width: 50px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto';
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
  }
  </style>