<template>
  
  <div class="container d-flex flex-column flex-grow-1 overflow-hidden "
    style="padding:4px 8px"
  > 

    <div class="question-area d-flex flex-column flex-grow-1
      px-3 py-2  oveflow-container "
      ref="questionArea"
      
    >
      <div class="flex-grow-1 flex-shrink-0 d-flex flex-column">
        <slot :name="pageName"/>
      </div>
       
    </div>
   
   <div>
      <div class="d-flex flex-row align-items-stretch justify-content-between py-2"
      >

        <button 
        v-if="hasBack"
        class="btn btn-info  mr-1 pt-2 pr-2" 
          @click="goBack">
          <i class="fas fa-arrow-left"></i>
        </button>

        <div class="d-flex flex-column position-relative flex-grow-1">
          
          <next-button 
              @click="handleNext" 
              :text="nextText"
              :hasNext="nextText"
              :disabled="disabled"
          />

          <!-- <div class="w-100 h-100 d-flex justify-content-center cursor-pointer  position-absolute"
           style="background-color:#fffff0a0"
           v-if="!allow_reports && nextText == endbtext"
            @click="checkIfCreditLink()"
          >
            <p class="fas fa-lock text-pri my-auto" style="font-size:1.5em"></p>
          </div> -->

        </div>
      </div>

   </div>

  </div>

</template>

<script>
import NextButton from '../FormItems/NextButton'
export default {
    components:{NextButton},
    props:{
        plan:{},
        pages:{
            type:Number
        },
        currentPage:{
            default:1
        },
        previousPage:{},
        currentSection:{
          default:1
        },
        sections:{  },
        constraints:{
          type:Object
        },
        fdata:{type:Object},

    },
    data(){
      return {
        highestSection:0,
        highestPage:0,
        change:0,


         
      }
    },
    computed:{
      allow_reports(){
        return this.$store.getters.allow_reports
      },
      hasBack(){
        return !(this.currentSection == 0 && this.currentPage == 1)
      },
      endbtext(){
        return "GET REPORT"
      },
      disabled(){
        return this.currentSection==3 && !this.plan;
      },
      pageName(){
        return String.fromCharCode(97+this.currentSection)+this.currentPage;
      },
      nextText(){
        const numPages = (this.sections[this.currentSection].pages) 
        let ntext  = 'Next'
        if(this.currentSection == 2){
            if(this.currentPage == numPages){
              ntext = 'Confirm';
            }else if(this.currentPage == numPages-1){
              ntext = 'One Last Question';
            }
        }
        else if(this.currentSection == 3){
          // ntext = 'More Personalized Plans';
          ntext = this.endbtext
        }
        else if(this.currentSection == 4){
          if(this.currentPage == 1)
          ntext = 'send otp to mobile';
          else if (this.currentPage == this.sections[this.currentSection].pages){
            ntext = 'generate plan'
          }
          else if(this.currentPage > 2)
          ntext = 'save and next'
        }
        return ntext;
      }
    },
    methods:{
      checkIfCreditLink() {
          if (this.$route.path.indexOf('credit') > -1) {
              if (!this.$store.state.user) { // logged In
                  this.$emit('show-access-report-popup')
              }
          } else {
              this.$store.dispatch('showReportUnlockPayment')
          }
      },
      goBack(){
        this.change = -1;
        this.$emit('back');
      },
      showError(key){
        this.$emit('error',{message:(key||'This field')+' is required'});
      },
      handleNext(){
        
        const numPages = (this.sections[this.currentSection].pages);
        /*
        const keys = Object.keys(this.constraints);
        for(let i =0;i<keys.length;i+=1){
            const c = keys[i];
            if(this.pageName == c){
              const conKey = this.constraints[c];
              const cvalue = this.fdata[conKey];
              if((typeof cvalue == 'object') && (!Array.isArray(cvalue))){
                if(cvalue.selected == null) {
                  // post an error
                  this.showError(conKey);
                  return;
                }
              
              }else if(cvalue == null || cvalue == '') {
                //post an error
                this.showError();
                return;
              }
            }

        }
        */

        if(this.currentSection == 2 && this.currentPage == numPages){
          this.$emit('generate')
          // this.$store.dispatch('showPaymentForReport');
        }
        else if(this.currentSection == 3){
   
          this.$emit('endForm')
        }
        else{
          this.change = 1
          this.$emit('next');
        }


      },
      setAnimations(){
        // 
        // console.clear();
        const children = this.$refs.questionArea.childNodes 
        // 
        let i = 0;
  
        const shouldAnimate =  (this.currentSection != 3)
        if(shouldAnimate){
            children.forEach(
              c => {
                  // 
                  if(c.style){
                    const duration = 0.6;
                    c.style.opacity = "0";
                    // c.className +=" fade-in "
                    c.style.animation = "slide-fade-in-animation "+duration+"s"
                    // c.style.animationDuration = "0.8s"
                    c.style.animationName = ''
                    void c.offsetWidth;
                    c.style.animationName = 'slide-fade-in-animation'
                    const delayDifference  = duration*0.6;
                    c.style.animationDelay = (i*delayDifference)+"s";
                    c.style.animationFillMode = "forwards"
                    
                    i += 1;
                  }
              }
            )
        }else{
            children.forEach(
              c => {
                  if(c.style){
                    c.style.opacity = "1";
                    c.style.animation = "none"
                  }
              }
            )
        }

      },
      checkConditions(){
          let skip = false;
          const pn = this.pageName
          // 
          if(pn=='b1'){
          
            if(this.fdata.marital_status.selected==0){skip=true;}
                
          }
          const diff = this.change
          if(skip){
            // 
            if(diff>0){
              
              return this.$emit('next')
            }else{
              this.change = -1
              return this.$emit('back')
            }
          }

      }
    },
    mounted(){
      this.setAnimations();

    },
    watch:{
      currentPage(){
        this.checkConditions();
        const isNewPage = (this.currentSection*10+this.currentPage) > (this.highestSection*10+this.hightestPage);
        
        if(this.currentPage >= this.highestPage){this.hightestPage  = this.currentPage;}
        if(this.currentSection >= this.highestSection){this.highestSection  = this.currentSection}


        this.$nextTick(()=>{
          if(isNewPage){
            
            this.setAnimations();
          }
        })
      }
    }



}
</script>

<style>
  .oveflow-container{
    overflow-y: auto;
    overflow-x: hidden;

  }

</style>