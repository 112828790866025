<template>
    <div class="container my-form rounded-extra my-1 mb-sm-4  shadow bg-white">
        <Modal :show="loadingPlan" unclosable>
            <div style="width:12em;height:12em" class="d-flex align-items-center justify-content-center ">
                <img src="/images/shield.png" class="vloading mb-3 " alt="" style="width:60px;">
            </div>
        </Modal>


        <div v-if="showPlan" class="bg-white rounded-extra position-relative">
            <!-- <button class="fas fa-times-circle p-2 rounded 
            bg-transparent border-0 lead position-absolute
             float-right"
             style="opacity:0.2;top:0;right:0   "
            @click="$emit('clear-plan')"
        >
        </button> -->

            <plan-viewer class="p-2 p-sm-4 px-md-5 " :plan="planData" :formData="formData"
                @show-email-dialog="this.$emit('show-email-dialog')"
                @show-access-report-popup="this.$emit('show-access-report-popup')" />

            <div class="d-flex flex-column position-relative mb-4 ">

                <!-- <button class="std-button " @click="$emit('show-email-dialog')">
                    
                </button> -->
                <!-- <div class="w-100 h-100 d-flex justify-content-center cursor-pointer  position-absolute"
                    style="background-color:#fffff0a0" v-if="!$store.getters.allow_reports" @click="checkIfCreditLink()">
                    <p class="fas fa-lock text-pri my-auto" style="font-size:1.5em"></p>
                </div> -->
            </div>

        </div>
        <div v-else class="bg-white px-sm-4 px-2">
            <p class="secion-heading ">
                Personal Details
            </p>
            <div class="row " :class="colClass">
                <div class="col">
                    <form-field name='Full name of customer'>
                        <v-input type="text" class="form-text-input" v-model="fdata.name" />
                    </form-field>

                    <form-field name='Marital Status'>
                        <radio-select :resource="fdata.marital_status">
                        </radio-select>
                    </form-field>

                    <form-field name='Education'>
                        <radio-select :resource="fdata.education">
                        </radio-select>
                    </form-field>


                </div>

                <div class="col">

                    <form-field name='Gender'>
                        <radio-select :resource="fdata.gender">
                        </radio-select>
                    </form-field>

                    <div class="d-flex justify-content-start align-items-center0">

                        <form-field name='Location'>
                            <select-box ref="cityPicker" :resource="fdata.city" @change="pickCity" @click="clearCity">
                            </select-box>
                        </form-field>
                        <div class="mx-5 small-input">
                            <form-field name='Age'>
                                <v-input type="number" class="form-text-input" v-model="fdata.starting_age" max='100' />
                            </form-field>

                            <form-field name='Retirement at' style="white-space:nowrap;">
                                <v-input type="number" class="form-text-input" v-model="fdata.retirement_age" />
                            </form-field>
                        </div>
                    </div>


                </div>

            </div>

            <p class="secion-heading ">
                Family Details
            </p>

            <div class="row " :class="colClass">
                <div class="col">
                    <form-field name='Spouse Dependency'>
                        <radio-select :resource="fdata.spouse_dependent">
                        </radio-select>
                    </form-field>

                    <form-field name='Dependent Children & Ages'>
                        <div class="d-flex flex-column">
                            <v-input type="number" readOnly class="form-text-input mb-2 medium-input"
                                :value="fdata.children.length" @input="handleChildren" min="0" ref="chilInput" />
                            <div class="d-flex ">
                                <v-input v-for="(c, n) in fdata.children" :key="n" type="number"
                                    class="form-text-input mx-1 small-input" v-model="c.age" max='24' />

                            </div>
                        </div>
                    </form-field>



                </div>

                <div class="col">
                    <!-- <form-field name='Spouse age'>
                        <v-input type="number" class="form-text-input " 
                                        v-model="fdata.spouse_age"
                        />
                    </form-field> -->


                    <form-field name='Dependent Parents & Ages'>
                        <div>

                            <div class="d-flex align-items-end mt-3" style="height:3em" v-for="p in ['father', 'mother']"
                                :key="p">
                                <div class="d-flex align-items-center my-2">
                                    <check-box v-model="fdata[p + '_dependent']" class="mr-2" />
                                    <p class="text-lightgray my-2 mx-1 p-0 mr-3 text-capitalize">
                                        {{ p }}
                                    </p>
                                </div>
                                <div class="d-flex align-items-end" v-if="fdata[p + '_dependent']">
                                    <p class="mr-3   p-0  text-muted">aged</p>
                                    <v-input type="number" class="form-text-input small-input " v-model="fdata[p + '_age']" />
                                </div>
                            </div>

                        </div>

                    </form-field>
                </div>
            </div>


            <p class="secion-heading ">
                Financial Details
            </p>

            <div class="row " :class="colClass">
                <div class="col">
                    <form-field name='Customer Occupation'>
                        <radio-select :resource="fdata.occupation_self">
                        </radio-select>
                    </form-field>

                    <form-field name='Home'>
                        <radio-select :resource="fdata.home_owned">
                        </radio-select>
                    </form-field>

                    <form-field name='Home Size (in square feet)'>
                        <v-input type="number" class="form-text-input " v-model="fdata.home_area" max='10000' />
                    </form-field>

                    <form-field name='Home Loan Availed (in Lakhs)'>
                        <v-input type="number" class="form-text-input " v-model="fdata.home_loan_availed" max='500' />
                    </form-field>

                    <form-field name='Any Other Loans (in Lakhs)?'>
                        <v-input type="number" class="form-text-input " v-model="fdata.other_loans_availed" max='100' />
                    </form-field>

                    <form-field name='Assets (in Lakhs)'>
                        <div class="d-flex">
                            <form-field name="Financial" mini>
                                <v-input type="number" class="form-text-input " v-model="fdata.total_financial_assets"
                                    max='500' />
                            </form-field>
                            <form-field name="Real Estate" class="ml-3" mini>
                                <v-input type="number" class="form-text-input " v-model="fdata.total_real_estate_assets"
                                    max='500' />
                            </form-field>
                        </div>
                    </form-field>

                    <form-field name="Home Goal (in Lakhs) (optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_home_cost" max='100' />
                    </form-field>
                    <form-field name="Home Savings (in Lakhs) (optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_home_savings" max='100' />
                    </form-field>

                    <form-field name="Achieve Home goal? (optional)">
                        <Dropdown :max=40 v-model="fdata.goals_home_year" />
                    </form-field>

                    <form-field name="Kids Marriage Goal (in Lakhs) (optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_marriage_cost" max='100' />
                    </form-field>
                    <form-field name="Kids Marriage Savings (in Lakhs) (optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_marriage_savings" max='100' />
                    </form-field>

                    <form-field name="Achieve Kids Marriage goal? (optional)">
                        <Dropdown :max=40 v-model="fdata.goals_marriage_year" />
                    </form-field>

                </div>

                <div class="col">
                    <form-field name='Annual Income in Lakhs'>
                        <div class="d-flex">
                            <form-field mini name="Job">
                                <v-input type="number" class="form-text-input " v-model="fdata.annual_income_self"
                                    max='100' />
                            </form-field>
                            <form-field mini name="Others" class="ml-3">
                                <v-input type="number" class="form-text-input " v-model="fdata.annual_income_other"
                                    max='100' />
                            </form-field>
                        </div>
                    </form-field>

                    <form-field name='Spouse Occupation'>
                        <radio-select :resource="fdata.occupation_spouse">
                        </radio-select>
                    </form-field>

                    <form-field name="Spouse's Annual Income (in Lakhs)">
                        <v-input type="number" class="form-text-input " v-model="fdata.annual_income_spouse" max='100' />
                    </form-field>

                    <form-field name="Investment for Retirement (in Lakhs)">
                        <v-input type="number" class="form-text-input " v-model="fdata.current_investment_for_retirement"
                            max='500' />
                    </form-field>

                    <form-field name="Annual Household Expenses (in Lakhs)" v-if="options.showHouseholdExpenses">
                        <v-input type="number" class="form-text-input" v-model="fdata.current_household_expenses"
                            max='100' />
                    </form-field>

                    <form-field name="Kids Education Goal (in Lakhs) (optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_education_cost" max='100' />
                    </form-field>
                    <form-field name="Kids Education Savings(in Lakhs)(optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_education_savings" max='100' />
                    </form-field>

                    <form-field name="Achieve Kids Education goal? (optional)">
                        <Dropdown :max=40 v-model="fdata.goals_education_year" />
                    </form-field>
                    <form-field name="Vacation Goal (in Lakhs) (optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_holiday_cost" max='100' />
                    </form-field>
                    <form-field name="Vacation Savings (in Lakhs) (optional)">
                        <v-input type="number" class="form-text-input" v-model="fdata.goals_holiday_savings" max='100' />
                    </form-field>

                    <form-field name="Achieve Vacation goal? (optional)">
                        <Dropdown :max=40 v-model="fdata.goals_holiday_year" />
                    </form-field>

                </div>
            </div>

            <div class="d-flex justify-content-center mt-5 my-4">
                <check-box v-model="fdata.agree" class="mx-2" />
                <span>
                    I agree to all
                    <span class="ml-1 p-0 text-info cursor-pointer my-link" @click="$emit('show-terms')">
                        Terms and Conditions
                    </span>
                </span>

            </div>


            <button class="bg-green logo-font border-0 my-4
            font-weight-bold p-2 px-3 rounded" @click="$emit('generate-plan')">
                Generate Recommendation
            </button>

        </div>







    </div>
</template>

<script>
import FormField from './FormField'
import RadioSelect from '../common/RadioSelect'
import SelectBox from '../common/SelectBox'
import CheckBox from '../input/CheckBox'
import Modal from '../common/Modal'
import PlanViewer from '../special/PlanViewer'
import VInput from '../input/VInput'
import Dropdown from "../../components/input/Dropdown"

export default {
    components: {
        FormField,
        RadioSelect,
        SelectBox,
        CheckBox,
        Modal,
        PlanViewer,
        VInput,
        Dropdown,
    },
    props: {
        fdata: { type: Object, required: true },
        loadingPlan: {},
        plan: {},
        formData: {},
        planData: {},
        options: { type: Object },
    },
    data() {
        return {
            marital_status: { selected: 0 },
            colClass: 'row-cols-md-2 row-cols-1'
        }
    },
    computed: {
        showPlan() {
            return !!this.plan
        }
    },
    methods: {
        checkIfCreditLink() {
            if (this.$route.path.indexOf('credit') > -1) {
                if (!this.$store.state.user) { // logged In
                    if (this.$store.state.creditLinkCredits > 0 && this.$store.state.creditValidity > new Date().toISOString()) {
                        this.$emit('show-access-report-popup')
                    } else {
                        this.$store.dispatch('showReportUnlockPayment')
                    }
                }
            } else {
                this.$store.dispatch('showReportUnlockPayment')
            }
        },
        clearCity() {
            // this.fdata.city.options[7].name = 'Other'
        },
        pickCity(k) {
            if (k == 7) {
                this.$emit('get-location')
            }
        },
        handleChildren(e) {
            const maxChil = 8
            const inp = this.$refs.chilInput
            let newv = e.target.value


            if (newv > maxChil) {
                inp.setValue(maxChil)
                newv = maxChil
            }
            const nchil = this.fdata.children.length
            const lack = newv - nchil;
            if (newv > -1 && newv <= maxChil) {
                if (lack > 0) {
                    for (let i = 0; i < lack; i += 1) {
                        this.fdata.children.push({ age: 6 })
                    }
                } else if (lack < 0) {
                    const extra = -lack
                    this.fdata.children.splice(newv - 1, extra)

                }
            }

        }
    }
}
</script>

<style scoped>
.secion-heading {
    background-color: #d3e1f47c;
    text-transform: uppercase;
    color: var(--darkblue);
    margin-top: 16px;
    font-size: 0.9em;
    font-weight: 500;
    padding: 6px;
    border-radius: 4px;
}

.small-input {
    max-width: 6em;
}

.medium-input {
    max-width: 9em;
}
</style>