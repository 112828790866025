<template>
  <div class=" position-relative "
    id="picture-progress"
  >
    <img src="/images/background/Rectangle.svg" alt="background"
      id="background-image"
    >


    <!-- <img id="tree1" src="/images/background/tree1.svg" alt=""> -->


    <img 
      v-for="i in cimages" :key="i.id"
      :id="i.id" 
      class="position-absolute progress-picture"
      :class="i.id=='city'?'grayscale':''"
      :src="imgUrl(i.id)" alt=""
      v-show="page_num >= i.min"
    >


  </div>
</template>

<script>
const female_min  = 12;
const male_min = 11;
export default {
  props:['currentSection','sections','currentPage','fdata'],

  data(){
    return{

      images:[
        {
          id:'city',min:13,
        },
        {
          id:'bg_props',min:0,
        },
        {
          id:'home',min:33,
        },  
        {
          id:'male_user',min:male_min,
        },
        {
          id:'female_user',min:female_min,
        },
        {
          id:'daughter',min:43,
        },
        {
          id:'parent',min:43,
        },
        {
          id:'son',min:43,
        },
      ]
    }
  },
  computed:{
    page_num(){
      return (this.currentSection+1) * 10 + this.currentPage;
    },
    cimages(){
      let c = this.images;
      const mindex = c.find(i => i.id=='male_user');
      const findex = c.find(i => i.id=='female_user');
      const dindex = c.find(i => i.id=='daughter');
      const sindex = c.find(i => i.id=='son');
      if(this.fdata.family.selection.length > 0){
        if (this.fdata.family.selection.includes('Self')){
          if(this.fdata.gender.selected == 1) {
            mindex.min =  male_min;
          }else {
            findex.min = male_min;
          }
        }
        if (this.fdata.family.selection.includes('Partner')){
          if(this.fdata.gender.selected == 0 ) {
            mindex.min =  male_min;
          }else {
            findex.min = male_min;
          }
        }else {
          if(this.fdata.gender.selected == 1) {
            mindex.min =  male_min;
            findex.min = female_min;
          }else {
            findex.min = male_min;
            mindex.min =  female_min;
          }
        }
        if (this.fdata.family.selection.includes('Daughter')){
            dindex.min = male_min
        }else {
          dindex.min = 23
        }
        if (this.fdata.family.selection.includes('Son')){
            sindex.min = male_min
        } else {
          sindex.min = 23
        }
        // mindex.min = female_min;
        // findex.min = male_min;
      } else {
        if(this.fdata.gender.selected == 0) {
          mindex.min =  female_min;
          findex.min = male_min;
        } else {
          mindex.min =  male_min;
          findex.min = female_min;
        }
      }
      // 
      return c;
    }

  },methods:{
    imgUrl(id){
      if(id == 'city'){
        const fc = this.fdata.city;
        if (fc.selected) {
          let cityName = (fc.options[fc.selected]).name;
          // cityName = (cityName=='Other')?'Mumbai':cityName
          return `/images/cities/${cityName}.svg`
        }
      }
      return `/images/background/${id}.png`;
    }
  }


}
</script>

<style>
.progress-picture{
  width: 6%;
  bottom: 0;
  left: 0%;
  transform-origin: bottom;
  transform: scale(0.7);
}

#picture-progress{
  width: 100%;
  /* max-height: 138px; */
  /* border: 1px solid yellow; */

}

#background-image{
  min-height: 128px;
  /* max-height: 184px; */
  max-height: 28vh;
  /* border: 2px solid red; */
  width: 100%; 
}


@media screen and (max-width: 576px) {
  #picture-progress{
    width: 100vw;
  }
  .progress-picture{
    transform: scale(0.82);
  }
  #background-image{
    min-height: 0px;
    max-height: 30vh;

    /* max-height: 24vh; */

  }
}


#bg_props{
  width: 90%;
  left: 5%;
  bottom: 0;
}
#male_user{
  left: 70%;   
}
#female_user{
  left: 64%;   
}
#child{
  left: 20%;   
  width: 4.5%;
}
#daughter{
  left: 74%;   
  width: 4.5%;
}
#son{
  left: 60%;   
  width: 4.5%;
}
#parent{
  left: 80%; 
  width: 5%;  
}

#home{
  width: 50%;
  left: 16%;
}
#city{
  left: 4%;
  width: 50%;
  max-height: 54%;;
   
}

.grayscale{
  filter: grayscale(80%);
  opacity: 0.3;
}

</style>