<template>
  <div class="dropdown">
    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ selectedYear }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="#" v-for="year in years" :key="year" @click.prevent="selectYear(year)">{{ year }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    max: {
      type: Number,
      default: 40
    },
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedYear: this.value,
      years: this.generateYears()
    };
  },
  methods: {
    generateYears() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: this.max }, (v, i) => currentYear - i);
    },
    selectYear(year) {
      this.selectedYear = year;
      this.$emit('input', year);
    }
  }
};
</script>

<style scoped>
.dropdown {
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
